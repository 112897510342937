export const productAdded = product => {
  if (typeof fbq === "function") {
    fbq("track", "AddToCart", {
      currency: "DKK",
      value: product.price,
      content_name: product.name,
    })
  }
}

export const checkoutStarted = cart => {
  if (typeof fbq === "function") {
    fbq("track", "InitiateCheckout", {
      currency: "DKK",
      value: cart.total,
      num_items: cart.items.length,
    })
  }
}

export const orderCreated = order => {
  if (typeof fbq === "function") {
    fbq("track", "Purchase", {
      currency: "DKK",
      value: order.total,
      num_items: order.items.length,
    })
  }
}

export const paymentDetailsAdded = order => {
  if (typeof fbq === "function") {
    fbq("track", "AddPaymentInfo", {
      currency: "DKK",
      value: order.total,
    })
  }
}
