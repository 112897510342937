import React from "react"
import styled from "@emotion/styled"
import qs from "query-string"

import { replace } from "gatsby"
import { request } from "../../services/api"
import { orderCreated } from "../../services/analytics"

import Layout from "../../components/Layout/Layout"
import LoadingSpinner from "../../components/ui/LoadingSpinner"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.background};
`

class CheckoutDone extends React.Component {
  state = {
    order: null,
  }

  componentDidMount() {
    const { i, t } = qs.parse(this.props.location.search)

    let type
    if (t === "pap") {
      type = "pay_at_pickup"
    } else if (t === "cc") {
      type = "credit_card"
    }

    if (type) {
      request("PATCH", "/carts/completed", {
        type,
        intentId: i,
      }).then(({ data }) => {
        orderCreated(data.order)
        replace(`/order/completed?t=${data.order.token}`)
      })
    }
  }

  render() {
    return (
      <Layout locale={"en"}>
        <Container>
          <LoadingSpinner />
        </Container>
      </Layout>
    )
  }
}

export default CheckoutDone
